import React from "react";
import NosotrosI from '../NosotrosI/NosotrosI'
import ServiciosI from '../ServiciosI/ServiciosI'

export default function Inicio() {
    return (
        <div>
            <NosotrosI title="Sobre Nosotros" img="images/Logo2.png" alt="Logo de MISAUTOMATION" texto={<p>Nuestro compromiso es identificar las necesidades de nuestros clientes, proporcionando el asesoramiento y servicio de calidad para dar soluciones eficientes con base al requerimiento del negocio u optimización de recursos</p>}></NosotrosI>
            <ServiciosI></ServiciosI>
        </div>
    )
}