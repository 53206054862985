import axios from 'axios'
import { Contacto } from '../interface/contacto'

class Api{
    url = "https://apisms.misautomation.com/insertar_contacto.php"

    public async addContact(contacto:Contacto){
        return axios.post(this.url, contacto)
    }
}

export default Api;