import React, { ReactPortal, useEffect } from "react";
import NosotrosI from '../NosotrosI/NosotrosI'
import DerechaV from '../DerechaV/DerechaV'
import IzquierdaV from '../IzquierdaV/IzquierdaV'
import Solicitar from '../Solicitar/Solicitar'

export default function Mensajes() {
    return (
        <div>
            <NosotrosI title="Envio_SMS" img="images/Inicio.png" alt="Pantalla de inicio Envio_SMS" texto={<p>La comunicación en la era digital es vital para aumentar la captación de clientes, pregunta por nuestra aplicación que automatiza tus SMS y WhatsApp.</p>}></NosotrosI>
            <DerechaV></DerechaV>
            <IzquierdaV></IzquierdaV>
            <Solicitar></Solicitar>
        </div>
    )
}