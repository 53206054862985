import React, { useEffect } from "react";

interface Props {
    title: string;
    img: string;
    alt: string;
    texto: any;
}

export default function NosotrosI({ title, img, alt, texto }: Props) {
    return (
        <div id="" className="about">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="titlepage">
                            <h2>{title}</h2>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="row w-50">
                        <div className="col-md-12">
                            <div className="web_about">
                                <img src={img} className="img-fluid" alt={alt} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="row w-75">
                        <div className="col-md-12">
                            <div className="web_about">
                                {texto}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}