import styled from 'styled-components'

const ModalWaitContainer = styled.div`
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

export const DekstopWaitContainer = styled(ModalWaitContainer)`
    border-radius: 50px;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
    padding: 40px;
    width: 100%;
    position: relative;
    font-size: 26px;
    opacity: 0.85;
`

export const Header = styled.h3`
  color: white;
  font-size: 35px;
  line-height: 1em;
  font-weight: 300;
  margin: 5px 0 10px;
  text-align: center;
`;