import React, { useEffect } from "react";
import { NavLink } from 'react-router-dom';

interface Props {
   subs: number;
}

export default function MenuP({ subs }: Props) {
   return (
      <header>
         <div className="header">
            <div className="container">
               <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col logo_section">
                     <div className="full">
                        <div className="center-desk">
                           <div className="logo">
                              <NavLink to='/'><a><img src="images/Logo.png" alt="#" /></a></NavLink>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                     <nav className="navigation navbar navbar-expand-md navbar-dark ">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                           <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarsExample04">
                           <ul className="navbar-nav mr-auto">
                              <li className="nav-item active">
                                 <NavLink to='/'><a className="nav-link">Inicio</a></NavLink>
                              </li>
                              <li className="nav-item">
                                 <NavLink to='/Mensajes'><a className="nav-link">Servicio Destacado</a></NavLink>
                              </li>
                              <li className="nav-item">
                                 <NavLink to='/Contacto'><a className="nav-link">Contáctanos</a></NavLink>
                              </li>
                           </ul>
                        </div>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </header>
   )
}