import React, { useEffect } from "react";
import ServicioI from './ServicioI'

export default function ServiciosI() {
    const subs = [];
    subs.push({ src: "images/Automatizacion.png", alt: "Icono de automatización", title: "Automatización", Texto: <p>Optimizar procesos con base a modelos ETL</p> })
    subs.push({ src: "images/RPA.png", alt: "Icono de RPA", title: "RPA", Texto: <p>- Generación de robots para realizar tareas repetitivas <br /> - WebScrapping para extracción de datos</p> })
    subs.push({ src: "images/Macros.png", alt: "Icono de Macros", title: "Macros", Texto: <p>- Generación de macros con Excel y Acces <br /> - Optimización de tareas en paquetes de office</p> })
    subs.push({ src: "images/Analytics.png", alt: "Icono de Analytics", title: "Analytics", Texto: <p>- Análisis de comportamiento de carteras de cobranza y ventas <br />- Estrategias utilizando score de comportamiento</p> })
    subs.push({ src: "images/Dashboards.png", alt: "Icono de Dashboards", title: "Dashboards", Texto: <p>- Tableros dinámicos con indicadores de rendimiento de todo tipo de operación <br />- ANS de procesos</p> })
    subs.push({ src: "images/Desarollos Tecnologicos.png", alt: "Icono de Desarollos Tecnologicos", title: "Desarollos Tecnológicos", Texto: <p>- Paginas Web con base a la necesidad del negocio <br />-Aplicaciones a nivel CRM</p> })
    return (
        <div id="service" className="Services">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="titlepage">
                            <h2>Nuestros Servicios</h2>
                        </div>
                    </div>
                </div>
                <ServicioI subs={subs}></ServicioI>

            </div>
        </div>
    )
}