import React, { useEffect } from "react";

interface Props {
    subs: number;
}

export default function RightP() {
    return (
        <div className="copyright">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>© 2022 All Rights Reserved. MISAUTOMATION</p>
                    </div>
                </div>
            </div>
        </div>
    )
}