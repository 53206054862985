import React from 'react';
import ModalWait from './ModalWait';
import { DekstopWaitContainer, Header } from './ModalPopupWait.styles'

interface BaseModalWaitProps{
    isModalVisible: number;
}

const BaseModalWait: React.FC<BaseModalWaitProps> = ({isModalVisible}) => {
    if(isModalVisible == 0){
        return null
    }else if (isModalVisible == 1){
        return(<ModalWait>
            <DekstopWaitContainer>
                <Header>Enviando</Header>
                <img src="images/Cargando.gif" alt="#" />
            </DekstopWaitContainer>
        </ModalWait>)
    }else if (isModalVisible == 2){
        return(<ModalWait>
            <DekstopWaitContainer>
                <Header>¡Gracias, Pronto nos comunicaremos con usted!</Header>
            </DekstopWaitContainer>
        </ModalWait>)
    }
    return(<ModalWait>
        <DekstopWaitContainer>
            <img src="images/Check.gif" alt="#" />
        </DekstopWaitContainer>
    </ModalWait>)
}

export default BaseModalWait