import React, { ReactPortal, useEffect } from "react";

export default function IzquierdaV() {
    return (
        <div className="IzquierdaV row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <h3>Mensajes Whatsapp</h3>
                <p>Envie de mensajes por whatsapp de forma masiva y automatizada de manera facil, segura y eficiente sin necesidad de WhatsApp Business</p>
                <br />
                <br />
                <div className="d-flex justify-content-center">
                    <div className="mw-300">
                        <img src='images/Whatsapp_Logo.png' className="img-fluid" alt="Icono de la app de mensajes de Android" />
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="embed-responsive embed-responsive-16by9">
                    <video src="video/WhatsApp.mp4" controls></video>
                </div>
            </div>
        </div>
    )
}