import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MenuP from './components/MenuP/MenuP'
import Inicio from './components/Inicio/Inicio'
import Mensajes from './components/Mensajes/Mensajes'
import Contacto from './components/Contactanos/Contactanos'
import RightP from './components/RightP/RigthP'
import './App.css';

const INITIALSTATE = 0

function App() {
  const [subs, setSubs] = useState<number>(0);
  useEffect(() => {
    setSubs(INITIALSTATE)
  }, [])
  return (
    <div className="App">
      <BrowserRouter>
        <MenuP subs={subs}></MenuP>
        <Routes>
          <Route path="/" element={<Inicio></Inicio>}></Route>
          <Route path="/Mensajes" element={<Mensajes></Mensajes>}></Route>
          <Route path="/Contacto" element={<Contacto></Contacto>}></Route>
        </Routes>
        <RightP></RightP>
      </BrowserRouter>
    </div>
  );
}

export default App;
