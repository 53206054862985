import React, { useState } from 'react';
import { Contacto } from '../../interface/contacto'
import ModalGet from './ModalGet';
import Api from '../../config/Api'
import BaseModalWait from '../BaseWait/BaseModalWait'
import BaseModalError from '../BaseError/BaseModalError'

interface BaseModalGetProps {
    isModalVisible: number;
    onBackdropClick: () => void;
}

const BaseModalGet: React.FC<BaseModalGetProps> = ({ isModalVisible, onBackdropClick }) => {
    if (isModalVisible == 0) {
        return null
    }

    const [Contact, setContact] = useState<Contacto>({
        'NOMBRE': '',
        'CORREO': '',
        'TELEFONO': '',
        'SERVICIO': 'Compra de Mensajes SMS',
        'MENSAJE': 'Se desea adquirir Mensajes SMS'
    })

    const [isWaitVisible, setIsWaitVisible] = useState(0)
    const [isErrorVisible, setIsErrorVisible] = useState(0)
    const [ErrorType, setErrorType] = useState('')

    const delay = (ms: number | undefined) => new Promise(r => setTimeout(r, ms));

    const validateEmail = () => {
        var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        if (validEmail.test(Contact.CORREO)) {
            return true;
        } else {
            return false;
        }
    }

    const buttonHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        if (Contact.NOMBRE != '' && Contact.CORREO != '' && Contact.TELEFONO != '' && Contact.TELEFONO.length == 8 && validateEmail()
            && Contact.TELEFONO.split('')[0] != '0' && Contact.TELEFONO.split('')[0] != '1' && Contact.TELEFONO.split('')[0] != '8' && Contact.TELEFONO.split('')[0] != '9') {
            const api = new Api()
            setIsWaitVisible(1)
            const responsive = (await api.addContact(Contact))
            setIsWaitVisible(2)
            await delay(5000);
            setIsWaitVisible(0)

            setContact({
                'NOMBRE': '',
                'CORREO': '',
                'TELEFONO': '',
                'SERVICIO': 'Compra de Mensajes SMS',
                'MENSAJE': 'Se desea adquirir Mensajes SMS'
            })
            onBackdropClick()
        } else {
            if (Contact.NOMBRE == '') {
                setErrorType("Debe ingresar un nombre")
            } else if (Contact.CORREO == '') {
                setErrorType("Debe ingresar un correo electronico")
            } else if (!validateEmail()){
                setErrorType("Debe ingresar un correo electronico valido")
            } else if (Contact.TELEFONO == '') {
                setErrorType("Debe ingresar un telefono")
            } else {
                setErrorType("Debe ingresar un telefono valido")
            }
            setIsErrorVisible(1)
            await delay(2000);
            setErrorType("")
            setIsErrorVisible(0)
        }
    };

    const CancelHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        onBackdropClick()
    };

    return (<ModalGet>
        <div id="contact" className="contact">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <form className="main_form">
                            <div className="row">
                                <div className="col-sm-12">
                                    <input className="contactus" placeholder="Nombre" type="text" name="Name" value={Contact.NOMBRE} onChange={ev => setContact({
                                        ...Contact,
                                        NOMBRE: ev.target.value
                                    })}></input>
                                </div>
                                <div className="col-sm-12">
                                    <input className="contactus" placeholder="Correo" type="email" name="Email" value={Contact.CORREO} onChange={ev => setContact({
                                        ...Contact,
                                        CORREO: ev.target.value
                                    })}></input>
                                </div>
                                <div className="col-sm-12">
                                    <input className="contactus" placeholder="Teléfono" type="number" name="Phone" value={Contact.TELEFONO} onChange={ev => setContact({
                                        ...Contact,
                                        TELEFONO: ev.target.value
                                    })}></input>
                                </div>
                                <div className="col-sm-6">
                                    <button className="get" onClick={buttonHandler}>Enviar</button>
                                </div>
                                <div className="col-sm-6">
                                    <button className="get" onClick={CancelHandler}>Cancelar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-root-wait">
            <BaseModalWait isModalVisible={isWaitVisible}></BaseModalWait>
        </div>
        <div id="modal-root-Error">
            <BaseModalError isModalVisible={isErrorVisible} ErrorType={ErrorType}></BaseModalError>
        </div>
    </ModalGet>)
}

export default BaseModalGet