import React, { ReactPortal, useEffect, useState } from "react";
import BaseModalGet from '../BaseGet/BaseModalGet'

export default function Solicitar() {

    const [isWaitVisible, setIsWaitVisible] = useState(0)

    const buttonHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        setIsWaitVisible(1)
    };

    const backDropSolicitar = () => {
        setIsWaitVisible(0)
    }

    return (
        <div className="">
            <div className="Solicitar row">
                <div className="col-sm-12">
                    <h3>Solicítelo hoy</h3>
                    <button className="Soli" onClick={buttonHandler}>Solicitar</button>
                </div>
                <div id="modal-root-get">
                    <BaseModalGet isModalVisible={isWaitVisible} onBackdropClick={backDropSolicitar}></BaseModalGet>
                </div>
            </div>
        </div>
    )
}