import React, { PropsWithChildren, MouseEventHandler, WheelEvent, useEffect } from 'react';
import styled from 'styled-components'
import ReactDOM from 'react-dom';

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalWait: React.FC<PropsWithChildren> = ({ children }) => {
    return ReactDOM.createPortal(<div>
        <Overlay>
            <div>{children}</div>
        </Overlay>
    </div>, document.getElementById('modal-root-wait')!);
}

export default ModalWait