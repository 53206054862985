import React, { useState } from "react";
import { Contacto } from '../../interface/contacto'
import Api from '../../config/Api'
import BaseModalWait from '../BaseWait/BaseModalWait'
import BaseModalError from '../BaseError/BaseModalError'

interface Props {
   subs: number;
}

export default function Contactanos() {

   const [Contact, setContact] = useState<Contacto>({
      'NOMBRE': '',
      'CORREO': '',
      'TELEFONO': '',
      'SERVICIO': '',
      'MENSAJE': ''
   })

   const [isWaitVisible, setIsWaitVisible] = useState(0)
   const [isErrorVisible, setIsErrorVisible] = useState(0)
   const [ErrorType, setErrorType] = useState('')

   const delay = (ms: number | undefined) => new Promise(r => setTimeout(r, ms));

   const validateEmail = () => {
      var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
      if (validEmail.test(Contact.CORREO)) {
          return true;
      } else {
          return false;
      }
  }

   const buttonHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const button: HTMLButtonElement = event.currentTarget;
      if (Contact.NOMBRE != '' && Contact.CORREO != '' && Contact.TELEFONO != '' && Contact.SERVICIO != '' && Contact.SERVICIO != 'Servicio' && Contact.MENSAJE != '' && Contact.TELEFONO.length == 8 
      && validateEmail() && Contact.TELEFONO.split('')[0] != '0' && Contact.TELEFONO.split('')[0] != '1' && Contact.TELEFONO.split('')[0] != '8' && Contact.TELEFONO.split('')[0] != '9') {
         const api = new Api()
         setIsWaitVisible(1)
         const responsive = (await api.addContact(Contact))
         setIsWaitVisible(3)
         await delay(1250);
         setIsWaitVisible(0)
         setContact({
            'NOMBRE': '',
            'CORREO': '',
            'TELEFONO': '',
            'SERVICIO': '',
            'MENSAJE': ''
         })
      } else {
         if (Contact.NOMBRE == '') {
            setErrorType("Debe ingresar un nombre")
         } else if (Contact.CORREO == '') {
            setErrorType("Debe ingresar un correo electronico")
         } else if (!validateEmail()){
            setErrorType("Debe ingresar un correo electronico valido")
        } else if (Contact.TELEFONO == '') {
            setErrorType("Debe ingresar un telefono")
         } else if (Contact.SERVICIO == '' || Contact.SERVICIO == "Servicio") {
            setErrorType("Debe seleccionar un servicio")
         } else if (Contact.MENSAJE == '') {
            setErrorType("Debe ingresar un mensaje")
         } else {
            setErrorType("Debe ingresar un telefono valido")
         }
         setIsErrorVisible(1)
         await delay(2000);
         setErrorType("")
         setIsErrorVisible(0)
      }
   };

   return (
      <div>
         <div id="contact" className="contact">
            <div className="container">
               <div className="row">
                  <div className="col-md-6 offset-md-3 ">
                     <form className="main_form">
                        <div className="row">
                           <div className="col-sm-12">
                              <input className="contactus" placeholder="Nombre" type="text" name="Name" value={Contact.NOMBRE} onChange={ev => setContact({
                                 ...Contact,
                                 NOMBRE: ev.target.value
                              })}></input>
                           </div>
                           <div className="col-sm-12">
                              <input className="contactus" placeholder="Correo" type="email" name="Email" value={Contact.CORREO} onChange={ev => setContact({
                                 ...Contact,
                                 CORREO: ev.target.value
                              })}></input>
                           </div>
                           <div className="col-sm-12">
                              <input className="contactus" placeholder="Teléfono" type="number" name="Phone" value={Contact.TELEFONO} onChange={ev => setContact({
                                 ...Contact,
                                 TELEFONO: ev.target.value
                              })}></input>
                           </div>
                           <div className="col-sm-12">
                              <select className="contactus" name="select" placeholder="Servicio" value={Contact.SERVICIO} onChange={ev => setContact({
                                 ...Contact,
                                 SERVICIO: ev.target.value
                              })}>
                                 <option value="Servicio" hidden selected>Servicio</option>
                                 <option value="Automatización">Automatización</option>
                                 <option value="RPA">RPA</option>
                                 <option value="Macros">Macros</option>
                                 <option value="Analytics">Analytics</option>
                                 <option value="Dashboards">Dashboards</option>
                                 <option value="Desarollos Tecnologicos">Desarollos Tecnologicos</option>
                                 <option value="Otros">Otros</option>
                              </select>
                           </div>
                           <div className="col-sm-12">
                              <textarea className="textarea" placeholder="Mensaje" name="Message" value={Contact.MENSAJE} onChange={ev => setContact({
                                 ...Contact,
                                 MENSAJE: ev.target.value
                              })}></textarea>
                           </div>
                           <div className="col-sm-12">
                              <button className="send" onClick={buttonHandler}>Enviar</button>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
         <div id="modal-root-wait">
            <BaseModalWait isModalVisible={isWaitVisible}></BaseModalWait>
         </div>
         <div id="modal-root-Error">
            <BaseModalError isModalVisible={isErrorVisible} ErrorType={ErrorType}></BaseModalError>
         </div>
      </div>
   )
}