import React from 'react';
import ModalError from './ModalError';
import { DekstopErrorContainer, Header, TextError } from './ModalPopupError.styles'

interface BaseModalErrorProps{
    isModalVisible: number,
    ErrorType: string;
}

const BaseModalError: React.FC<BaseModalErrorProps> = ({isModalVisible, ErrorType}) => {
    if(isModalVisible == 0){
        return null
    }else if (isModalVisible == 1){
        return(<ModalError>
            <DekstopErrorContainer>
                <Header>{ErrorType}</Header>
            </DekstopErrorContainer>
        </ModalError>)
    }
    return null
}

export default BaseModalError